import { cloneDeep, find, get } from 'lodash';

import { generateNumberOptions } from '../utils/Generators';

const PT_TO_PX = 4 / 3;
const PT_TO_DOCX = 2;
const LS_TO_LH = 1.15;
const LS_TO_TWIPS = 240;

// https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/rich/rich.html
export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', command: 'bold' },
  { label: 'Italic', style: 'ITALIC', command: 'italic' },
  { label: 'Underline', style: 'UNDERLINE', command: 'underline' },
];

export const ALIGNMENT_STYLES = [
  { label: 'Left', icon: 'alignLeft', value: 'left' },
  { label: 'Center', icon: 'alignCenter', value: 'center' },
  { label: 'Right', icon: 'alignRight', value: 'right' },
  { label: 'Justify', icon: 'alignJustify', value: 'justify' },
];

export const THEME_FONTS = [
  {
    key: 'Arial',
    displayName: 'Arial',
    baseFilename: 'Arimo',
    extension: 'ttf',
  },
  {
    key: 'Courier',
    displayName: 'Courier',
    baseFilename: 'FreeMonospaced',
    extension: 'ttf',
  },
  {
    key: 'Georgia',
    displayName: 'Georgia',
    baseFilename: 'Gelasio',
    extension: 'ttf',
  },
  {
    key: 'Helvetica',
    displayName: 'Helvetica',
    baseFilename: 'FreeSans',
    extension: 'ttf',
  },
  {
    key: 'Jakarta Sans',
    displayName: 'Jakarta Sans',
    baseFilename: 'PlusJakartaSans',
    extension: 'ttf',
  },
  {
    key: 'Times New Roman',
    displayName: 'Times New Roman',
    baseFilename: 'FreeSerif',
    extension: 'ttf',
  },
];

export const INTL_FONTS = [
  {
    key: 'latin',
    baseFilename: 'FreeSerif',
    extension: 'ttf',
  },
  {
    key: 'korean',
    baseFilename: 'NotoSansKR',
    extension: 'otf',
  },
  {
    key: 'japanese',
    baseFilename: 'NotoSansCJKjp',
    extension: 'ttf',
  },
];

export const OVERRIDE_EMBEDS = ['Courier', 'Helvetica', 'Times'];

// In PDF rendering, bold and italic are actually handled via separate font embedding
// So we need to enforce the order of font name construction to ensure it matches our embeds (see PDF.registerFontVariant)
// ie <Name>, <Name> Bold, <Name> Italic, <Name> Bold Italic
// Also, pdfkit includes 3 embedded fonts (see OVERRIDE_EMBEDS) (https://pdfkit.org/docs/text.html#fonts)
// We DON'T want to use them due to missing extended latin characters, but it's impossible to create aliases for them with the same names,
// So we can just do a simple lookup for these 3 and force the addition of 'Embed' on the font names
export const getFontVariantNamePDF = (font, bold, italic) => {
  let fontName = font;

  if (OVERRIDE_EMBEDS.includes(font)) {
    fontName += 'Embed';
  }

  if (bold) fontName += ' Bold';
  if (italic) fontName += ' Italic';

  return fontName;
};

export const FONT_SIZES = generateNumberOptions(8, 24, 0.5, 1);

export const LINE_SPACINGS = generateNumberOptions(1, 3, 0.1, 1);

// There is not yet UI to specify min-width on numbering
// (which needs to be the same as indent width, so that unnumbered sections show as flush with parent)
// TBD whether we'll actually ever make this dynamic, but for now at least we've got a const to use
export const NUMBER_INDENT = 32;

export const generateNative = ({ css, docx }) => {
  return {
    font: get(css, 'fontFamily', '').split(',')[0] || get(docx, 'run.font') || 'Times New Roman',
    color: get(css, 'color') || get(docx, 'run.color') || 'black',
    size: round(get(css, 'fontSize', '0').replace('px', '') / PT_TO_PX) || get(docx, 'run.size') || 12,
    lineSpacing:
      round(get(docx, 'paragraph.spacing.line', 0) / LS_TO_TWIPS) || round(get(css, 'lineHeight', 0) / LS_TO_LH) || 1,
    alignment: get(css, 'textAlign') || get(docx, 'paragraph.alignment') || 'left',
    bold: get(css, 'fontWeight') === 'bold',
    underline: get(css, 'textDecoration') === 'underline',
    italic: get(css, 'fontStyle') === 'italic',
  };
};

const round = (number, precision = 2) => {
  return Number(number.toPrecision(precision));
};

export default class TypeStyle {
  name = null;
  raw = null;

  constructor(json, name) {
    this.raw = json;
    this.name = name;
  }

  get isNative() {
    return !!this.raw.native;
  }

  get native() {
    return this.raw.native ? cloneDeep(this.raw.native) : generateNative(this.raw);
  }

  get css() {
    if (this.raw.css) return cloneDeep(this.raw.css);

    const native = this.native;

    return {
      fontFamily: native.font,
      color: native.color,
      fontSize: round(native.size * PT_TO_PX) + 'px',
      lineHeight: round(native.lineSpacing * LS_TO_LH),
      textAlign: native.alignment,
      fontWeight: native.bold ? 'bold' : 'normal',
      fontStyle: native.italic ? 'italic' : 'normal',
      textDecoration: native.underline ? 'underline' : 'none',
      // Special case for numbering as we don't have UI for this (yet)
      minWidth: ['OverviewNumber', 'SectionNumber'].includes(this.name) ? `${NUMBER_INDENT}px` : null,
    };
  }

  get docx() {
    if (this.raw.docx) return cloneDeep(this.raw.docx);

    const native = this.native;

    const spacing = {
      line: round(native.lineSpacing * LS_TO_TWIPS),
    };
    // Special case for table headers/rows because we don't have an equivalent of row height in TypeStyle or LayoutStyle
    // But adding half of the line height before/after each cell paragraph gets us extremely close to perfect alignment with web
    if (['TableHeader', 'TableBody'].includes(this.name)) {
      spacing.before = spacing.line / 2;
      spacing.after = spacing.line / 2;
    }

    return {
      // TODO: id too?
      id: this.name,
      name: this.name,
      // TODO: do we actually need these???
      // basedOn: 'Normal',
      // next: 'Normal',
      run: {
        font: native.font,
        size: round(native.size * PT_TO_DOCX),
        color: native.color,
        bold: native.bold,
        italics: native.italic,
        underline: native.underline,
      },
      paragraph: {
        alignment: native.alignment === 'justify' ? 'both' : native.alignment,
        spacing,
      },
    };
  }

  get pdf() {
    if (this.raw.pdf) return cloneDeep(this.raw.pdf);

    const native = this.native;

    const adjustment = 0.45;

    return {
      fontName: getFontVariantNamePDF(native.font, native.bold, native.italic),
      fontSize: native.size,
      color: native.color,
      align: native.alignment,
      underline: native.underline,
      // PDF uses lineGap, which is the space (in points, ie 72dpi) BETWEEN lines
      lineGap: (native.lineSpacing - 1) * native.size - adjustment,
    };
  }

  get selectedFont() {
    const fontName = get(this, 'native.font', 'Times New Roman');
    return find(THEME_FONTS, { key: fontName }) || THEME_FONTS[0];
  }

  get selectedSize() {
    const size = Math.round(get(this, 'native.size', 12) * 2) / 2; // Every 0.5
    return find(FONT_SIZES, { value: size }) || find(FONT_SIZES, { value: 12 });
  }

  get selectedLineSpacing() {
    const lineSpacing = Math.round(get(this, 'native.lineSpacing', 1) * 10) / 10; // Every 0.1
    return find(LINE_SPACINGS, { value: lineSpacing }) || find(LINE_SPACINGS, { value: 1 });
  }

  get json() {
    return this.raw;
  }
}
