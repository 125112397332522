import React, { Component } from 'react';

import Dropzone from 'react-dropzone';

import Attachment, { ATTACHMENT_TYPE } from '@core/models/Attachment';
import { imageSrc } from '@core/models/Variable';

const DEFAULT_MAX_SIZE = 1000000;

export default class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: imageSrc(this.props.image),
      error: null,
    };
  }

  UNSAFE_componentWillReceiveProps(p) {
    this.setState({ image: imageSrc(p.image) });
  }

  createAttachment(file) {
    const { deal } = this.props;
    return new Attachment(
      {
        extension: file.name.split('.').pop()?.toLowerCase() === 'jpg' ? 'jpeg' : file.name.split('.').pop(),
        title: file.name.split('.')[0],
        description: null,
        attachmentType: ATTACHMENT_TYPE.VARIABLE,
        date: file.lastModified,
      },
      deal
    );
  }

  onDropAccepted(files) {
    const { onImage, disabled } = this.props;

    if (disabled) return;

    if (files.length > 0) {
      this.setState({ error: null });
      const file = files[0];

      const reader = new FileReader();

      const generateImageAttachement = (arrayBuffer) => {
        const attachment = this.createAttachment(file);
        const attachmentBytes = new Uint8Array(arrayBuffer);
        onImage({ attachment, attachmentBytes });
      };

      reader.onload = (e) => generateImageAttachement(e.target.result);
      reader.onerror = () => onImage(null);
      reader.readAsArrayBuffer(file);
    }
  }
  onDropRejected(rejected) {
    if (rejected.length > 0) {
      const file = rejected[0];
      if (file.size > DEFAULT_MAX_SIZE) {
        this.setState({ error: 'File is too large.' });
      } else if (file.type.split('/')[0].toLowerCase() != 'image') {
        this.setState({ error: 'File must be a valid image.' });
      }
    }
    this.setState({ value: null });
  }

  render() {
    const { maxSize, onImage, disabled } = this.props;

    return (
      <Dropzone
        className="contents image-uploader"
        activeClassName="image-uploader active"
        maxSize={maxSize || DEFAULT_MAX_SIZE}
        accept="image/png,image/jpeg"
        disableClick={this.state.image != null || disabled}
        onDropAccepted={(a) => this.onDropAccepted(a)}
        onDropRejected={(r) => this.onDropRejected(r)}
        data-cy="contents-image-uploader"
        multiple={false}
      >
        <div className="image-uploader-inner" data-cy="image-uploader-inner">
          {this.state.image ? (
            <div className={`preview${disabled ? ' disabled' : ''}`} data-cy="image-uploader-preview">
              <img src={this.state.image} />
            </div>
          ) : (
            <div className="upload">
              <h4 className="lighter">Upload Image</h4>
              <small>
                Drag image here or tap to browse
                <br />
                PNG/JPG only, max size 1MB
              </small>
            </div>
          )}
          {this.state.image && !disabled && (
            <span className="remove-image" onClick={() => onImage(null)} data-cy="remove-image">
              ×
            </span>
          )}

          {this.state.error && <small className="error">{this.state.error}</small>}
        </div>
      </Dropzone>
    );
  }
}
