import React, { Component } from 'react';

import _ from 'lodash';

export default class PageBreak extends Component {
  render() {
    const { section } = this.props;
    const { deal, id } = section;

    const config = _.find(deal.pageBreakConfig, { id });

    return (
      <div className="page-break" data-cy="page-break" style={deal.style.layout.PageMargin.pageBreakMargin}>
        <span className="page-break-name">{config ? _.upperFirst(config.displayName) : 'Page Break'}</span>
      </div>
    );
  }
}
