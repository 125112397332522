import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import User from '@core/models/User';

import { Button, Combobox } from '@components/dmp';

import CONFIG from '@root/Config';

const NEW_KEY = { isCreateNew: true };

@autoBindMethods
export default class TeamSelector extends Component {
  static defaultProps = {
    teamID: null,
    teams: null,
    className: '',
    disableNew: false,
    toggleTeamCreation: null,
    onSelect: null,
    width: '100%',
    menuWidth: 270,
    enableEmpty: false,
    disabled: false,
    canCreate: false,
    createLabel: '',
    clearable: true,
  };

  static propTypes = {
    user: PropTypes.instanceOf(User),
    teamID: PropTypes.string,
    teams: PropTypes.array,
    className: PropTypes.string,
    dmpStyle: PropTypes.string,
    size: PropTypes.string,
    disableNew: PropTypes.bool,
    toggleTeamCreation: PropTypes.func,
    onSelect: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    menuWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    enableEmpty: PropTypes.bool,
    disableOption: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    canCreate: PropTypes.bool,
    createLabel: PropTypes.string,
    onClear: PropTypes.func,
    clearable: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTeam: props.team || null,
    };
  }

  selectTeam(team) {
    const { onSelect, toggleTeamCreation } = this.props;

    if (team.isCreateNew) {
      toggleTeamCreation(true);
      return;
    }

    onSelect(team.value);
  }

  render() {
    const {
      className,
      disableNew,
      dmpStyle,
      size,
      teamID,
      teams,
      width,
      enableEmpty,
      placeholder,
      disabled,
      clearable,
      onClear,
      createLabel,
      user,
    } = this.props;

    // "user" should always exist, but since it is heavily crashing when not, it's safer to ? it for now.
    const canCreate =
      !disableNew &&
      (!CONFIG.INSTANCE || (CONFIG.INSTANCE && (user?.isAdmin || user?.isPartner))) &&
      this.props.canCreate;

    let options = teams.map((t) => ({
      label: t.info.name,
      value: t.teamID,
      team: t,
    }));

    let defaultOption = null;
    if (teamID) {
      defaultOption = options.find((opt) => opt.value === teamID) || null;
    }

    options = options.sort((a, b) => a.label.localeCompare(b.label));

    // Here there are no teams but user CAN create, so just render basic button (no dropdown)
    if (teams && !teams.length && !disableNew) {
      return (
        <Button
          dmpStyle={dmpStyle}
          className="setup-team"
          data-cy="setup-team"
          onClick={() => this.selectTeam(NEW_KEY)}
        >
          Setup your team
        </Button>
      );
    }

    return (
      <Combobox
        data-cy="team-selector"
        options={options}
        onSelect={this.selectTeam}
        disabled={disabled}
        defaultValue={defaultOption}
        className={className}
        placeholder={placeholder || 'Select a team'}
        clearable={!disabled && clearable}
        searchable={true}
        enableEmpty={enableEmpty}
        width={width}
        onClear={onClear}
        size={size}
        canCreate={canCreate}
        createLabel={createLabel}
        customMessages={{
          emptyList: 'Loading Teams...',
          emptyFilter: 'No matching teams found.',
        }}
      />
    );
  }
}
