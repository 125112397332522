import _ from 'lodash';
import NumberConverter from 'number-to-words';

import { CurrencyFormat } from '../models/Payment';
import DateFormatter from './DateFormatter';

export const getValueAsNumber = (value) => {
  return Number.parseFloat(`${value}`.replace(/[$,%]/g, ''));
};

export const getDateProperty = (raw, property) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  let date = new Date();
  // Filevine's "Deadline" type Form Fields return objects:
  // {dateValue, doneDate} -- both can be dates or null
  // If we find one (which is the only way to get an object as a value), inspect the dateValue
  // (doneDate not yet supported, but we could add it as an additional Date property)
  if (typeof raw === 'object') {
    raw = raw.dateValue || null;
  }

  const dateMS = Date.parse(raw);

  // If value can't be parsed as a Date, just return raw value
  if (isNaN(dateMS)) return raw;

  //if the date is formatted as UTC YYYY-MM-DD add the offset to counteract the local time change.
  if (raw.match(regex) !== null) {
    date = new Date(dateMS + DateFormatter.getOffset(new Date(raw)));
  } else {
    date = new Date(raw);
  }

  // If we get a raw UTC date string (e.g., from an API) that successfully parses as a Date, but has time specified
  // Strip the time to allow for the date to be converted the same accross timezones.
  if (raw.includes('T') && date instanceof Date) {
    const UTCDate = raw.split('T');
    date = new Date(dateMS + DateFormatter.getOffset(new Date(UTCDate)));
  }

  // Enable ANY locale as Date properties! (e.g., en-CA)
  if (property && property.split('-').length === 2) {
    return DateFormatter.locale(date, property);
  }

  switch (property) {
    case 'raw':
      return raw;
    case 'day':
      return DateFormatter.day(date);
    case 'month':
      return DateFormatter.month(date);
    case 'year':
      return DateFormatter.year(date);
    case 'dayOfWeek':
      return DateFormatter.dayOfWeek(date);
    case 'dayOrdinal':
      return DateFormatter.dayOrdinal(date);
    case 'verbose':
      return DateFormatter.verbose(date);
    case 'none':
    case null:
    default:
      return DateFormatter.date(date);
  }
};

export const spell = (value, valueType) => {
  if (!value) return null;

  let s = '';
  // Ensure that .x gets converted to 0.x for both number.split('.') & NumberConverter.toWords()
  const valueNumber = getValueAsNumber(value);
  // Convert back to string for the split
  const pieces = `${valueNumber}`.split('.');
  const safeNumber = pieces[0].replace(/[,()\s]*/gi, '');

  let decimal = 0;

  // For idiot customers, who will remain unnamed, who type "Eight" for a numeric variable
  if (isNaN(safeNumber)) return value;

  s += NumberConverter.toWords(safeNumber);

  if (pieces.length > 1 && !isNaN(pieces[1])) decimal = Number.parseFloat(pieces[1]);

  //for currency, split on decimal and render 2nd piece as a separate number of cents
  if (valueType === 'currency') {
    s += ' dollar';
    if (!isNaN(safeNumber) && Number.parseFloat(safeNumber) > 1) s += 's';
    if (decimal > 0) s += ` and ${NumberConverter.toWords(decimal)} cent${decimal > 1 ? 's' : ''}`;
  }
  //for numbers and percentages, split on decimal but then render anything after a "point" one digit at a time
  else if (['number', 'percent'].includes(valueType) && decimal > 0) {
    s += ' point ';
    s += pieces[1]
      .split('')
      .map((c) => NumberConverter.toWords(c))
      .join(' ');
  }
  //finally add the word "percent" on all percentages
  if (valueType === 'percent') s += ' percent';

  return s;
};

export const transform = (value, textTransform) => {
  if (textTransform === 'uppercase') {
    if (Array.isArray(value)) {
      return value.map((v) => {
        if (typeof v === 'string') {
          return v?.toUpperCase();
        }
        return v;
      });
    }
    if (typeof value === 'string') {
      return value?.toUpperCase();
    }
  } else if (textTransform === 'lowercase') {
    if (Array.isArray(value)) {
      return value.map((v) => {
        if (typeof v === 'string') {
          return v?.toLowerCase();
        }
        return v;
      });
    }
    if (typeof value === 'string') {
      return value?.toLowerCase();
    }
  }
  return value;
};

export const format = (value, valueType, property = null, options = {}) => {
  const sigDigits = options?.sigDigits || null;
  const separator = options?.separator || ',';

  if (_.isNil(value)) return null;

  const valueString = String(value);
  let num = null;

  if (property === 'spelled' && ['number', 'currency', 'percent'].includes(valueType)) {
    return spell(valueString, valueType);
  }

  switch (valueType) {
    case 'string':
      if (Array.isArray(value)) {
        return !value.length ? null : value.join(separator);
      } else {
        return value;
      }
    case 'currency':
      num = getValueAsNumber(valueString);
      if (!isNaN(num)) {
        let cf = _.clone(CurrencyFormat);
        cf.minimumFractionDigits = 2;

        return num.toLocaleString('en-US', cf);
      }
      break;
    case 'percent':
      num = getValueAsNumber(valueString);
      if (!isNaN(num)) {
        const numString = num + '';
        let percent = num / 100;
        let pFormat = { style: 'percent' };
        //if there's a fraction, show up to 2 decimals; otherwise show none
        pFormat.maximumFractionDigits = sigDigits || (Math.floor(percent) != percent ? 2 : 0);
        //if fraction explicitly specified (even if it's like 4.000), that should override the above
        if (numString.indexOf('.') > -1) {
          const places = numString.split('.')[1].length;
          pFormat.minimumFractionDigits = sigDigits || Math.min(places, 5);
          pFormat.maximumFractionDigits = sigDigits || Math.min(places, 5);
        }

        return percent.toLocaleString('en-US', pFormat);
      }
      break;
    case 'date':
      return getDateProperty(value, property);
    case 'number':
      //ensure that number is rendered with however many decimals are provided
      num = getValueAsNumber(valueString);

      if (!isNaN(num)) {
        const numString = num + '';
        const nf = {
          useGrouping: true,
          minimumFractionDigits: 0,
          maximumFractionDigits: sigDigits || 5,
        };

        if (numString.indexOf('.') > 0) {
          const dec = numString.split('.')[1];
          nf.minimumFractionDigits = sigDigits || 0;
          nf.maximumFractionDigits = sigDigits || Math.min(nf.maximumFractionDigits, dec.length);
        }

        return num.toLocaleString('en-US', nf);
      }
      break;
    case 'contact':
      if (typeof value === 'object') {
        return value.fullname;
      }
    case 'select':
      if (property?.hasOwnProperty(value)) {
        return property[value].title;
      }
    case 'address':
      return value.split('\n').filter(v => !!v).join(', ');
  }

  return value;
};
